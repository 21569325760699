//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import './core/layout/base/layout';
@import 'layout/layout';

div:where(.swal2-icon) {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 2.5em auto 0.6em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  user-select: none;
}

div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg);
}

.max-width-380 {
  max-width: 380px;
}

.max-w-200 {
  max-width: 200px;
}

.bt-1 {
  border-bottom: 1px solid #000 !important;
}

.amount_due {
  border-top: 1px solid #000 !important;
}

.cutting-area {
  border-top: 2px dashed;
}

img.scissor {
  position: absolute;
  top: -9px;
  left: 10px;
}

.editable-textarea {
  border-radius: 0;
  resize: none;
  border: 1px solid #fff;

  &:hover {
    border-color: transparent;
  }

  &:focus-visible {
    border-color: transparent !important;
    // box-shadow: 1px -1px 4px #ddd;
  }
}

.credit-upload {
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 8px;
  padding-left: 16px;
  color: #212121;
}

.risk-progress-low {
  background: rgb(255, 0, 0);
  background: linear-gradient(270deg, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 1) 100%);
}

.risk-progress-med {
  background: rgb(255, 248, 0);
  background: linear-gradient(270deg, rgba(255, 248, 0, 1) 0%, rgba(255, 0, 0, 1) 100%);
}

.risk-progress-high {
  background: rgb(49, 142, 38);
  background: linear-gradient(270deg, rgba(49, 142, 38, 1) 0%, rgba(232, 241, 12, 1) 50%, rgba(255, 0, 0, 1) 100%);
}

.bubble {
  width: 110px;
  height: 64px;
  position: relative;
  background-color: #d5dadb;
  display: inline-block;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  top: -30px;
  color: #8e9697
}

.bubble:after {
  content: "";
  border-width: 12px 12px 0;
  border-style: solid;
  border-color: #d5dadb transparent;
  position: absolute;
  top: 100%;
  left: 40%;
}

.vertical-border {
  height: 60px;
  width: 2px;
  border-right: dashed 3px #ddd;
  position: absolute;
  top: 45px;
}

.progress-text {
  position: absolute;
  bottom: -80px;
  padding-left: calc(60% - 110px);
}

.risk-section {
  padding: 30px 16px 50px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 30px;
}

.fixed-column {
  position: sticky;
  left: 0;
  z-index: 99;
  background: white !important;
}

#kt_table_pkg th, #kt_table_pkg td {
  border-right: 1px dotted #666;
}

#kt_table_pkg th:last-child, #kt_table_pkg td:last-child {
  border-right: none;
}

#kt_table_pkg th:first-child, #kt_table_pkg td:first-child {
  border-right: none;
}
.companyBox:hover{
  background-color: #e8fff3;
}
.nav-link.active .companyBox {
  background-color: #e8fff3;
}
.w-40{
  width: 40% !important;
}

@media (min-width: 1200px) {
  .w-xl-380px {
      width: 380px !important;
  }
}